import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Navigate, useRoutes } from 'react-router-dom';
import AuthGuard from '../auth/AuthGuard';
import AuthorizationGuard from '../auth/AuthorizationGuard';
import GuestGuard from '../auth/GuestGuard';
import { useAuthContext } from '../auth/useAuthContext';
import { useNavConfig } from '../components/nav-section/vertical/config-navigation';
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
import MainLayout from '../layouts/main/MainLayout';
import findFirstAuthorizedItem from '../utils/findFirstAuthorizedItem';
import { AffiliationCreate, AffiliationEdit, AffiliationList, AffiliationView, AgenciesList, BankAccountCreate, BankAccountDetails, BankAccountEdit, BankAccountList, BranchCashboxCreate, BranchCashboxDetails, BranchCashboxEdit, BranchCashboxList, BranchCreate, BranchDetails, BranchEdit, BranchList, BrandCreate, BrandEdit, BrandList, BrandView, Calender, ClassCreatePage, ClassDetailsPage, ClassEditPage, Classes, ConfirmAccountMailPage, CreateAgencyPage, CreateReservation, CreateUserPage, CustomerDetails, CustomerList, Dashboard, EditAgencyPage, EditUsersPage, ExpenseConfigCreate, ExpenseConfigEdit, ExpenseConfigList, ExpenseCreate, ExpenseDetails, ExpenseEdit, ExpenseList, HomePage, InvoiceCreate, InvoiceEdit, InvoiceList, InvoiceView, LoginPage, ModelCreate, ModelEdit, ModelList, ModelView, Page403, Page404, Permissions, PricingGroupCreate, PricingGroupDetails, PricingGroupEdit, PricingGroupList, Register, RentableExtraTypeCreate, RentableExtraTypeEdit, RentableExtraTypeList, RentableExtraTypeView, RentableTypeCreate, RentableTypeEdit, RentableTypeList, RentableTypeView, RentalCreatePage, RentalDetailsPage, RentalEditPage, Rentals, ReservationDetails, ReservationEditPage, ReservationList, ReservationRejectedList, ResetPasswordPage, SeasonCreate, SeasonEditPage, Seasons, SeasonsDetailPage, ShowAgencyPage, UsersDetailPage, UsersList, VerifyCodePage, } from './elements';
import RoleBasedGuard from '../auth/RoleBasedGuard';
// ----------------------------------------------------------------------
export default function Router() {
    const { user } = useAuthContext();
    const navConfig = useNavConfig();
    const PATH_AFTER_LOGIN = findFirstAuthorizedItem(navConfig[0]?.items, user?.permissions, user?.role?.code, user?.active, user?.email);
    return useRoutes([
        {
            path: '/',
            element: _jsx(MainLayout, {}),
            children: [
                {
                    element: (_jsx(GuestGuard, { children: _jsx(HomePage, {}) })),
                    index: true,
                },
                {
                    path: '/about',
                    element: (_jsx(GuestGuard, { children: _jsx(HomePage, {}) })),
                    index: true,
                },
                {
                    path: '/location',
                    element: (_jsx(GuestGuard, { children: _jsx(HomePage, {}) })),
                    index: true,
                },
                {
                    path: '/categories',
                    element: (_jsx(GuestGuard, { children: _jsx(HomePage, {}) })),
                    index: true,
                },
                {
                    path: '/testimonials',
                    element: (_jsx(GuestGuard, { children: _jsx(HomePage, {}) })),
                    index: true,
                },
            ],
        },
        {
            path: 'register',
            element: (_jsx(GuestGuard, { children: _jsx(Register, {}) })),
        },
        {
            path: '/reset-password',
            element: (_jsx(GuestGuard, { children: _jsx(ConfirmAccountMailPage, {}) })),
        },
        {
            path: '/reset-password/:email',
            element: (_jsx(GuestGuard, { children: _jsx(ResetPasswordPage, {}) })),
        },
        {
            path: '/confirm-account/:email',
            element: _jsx(VerifyCodePage, {}),
        },
        {
            path: '/',
            element: (_jsx(AuthGuard, { children: _jsx(DashboardLayout, {}) })),
            children: [
                { element: _jsx(Navigate, { to: PATH_AFTER_LOGIN || 'login', replace: true }), index: true },
                {
                    path: 'login',
                    element: (_jsx(GuestGuard, { children: _jsx(LoginPage, {}) })),
                },
                {
                    path: '/dashboard',
                    children: [
                        {
                            element: (_jsx(AuthorizationGuard, { action: "list", entity: "dashboard", children: _jsx(Dashboard, {}) })),
                            index: true,
                        },
                    ],
                },
                {
                    path: 'branches',
                    children: [
                        {
                            element: (_jsx(AuthorizationGuard, { action: "list", entity: "branch", children: _jsx(BranchList, {}) })),
                            index: true,
                        },
                        {
                            path: 'new',
                            element: (_jsx(AuthorizationGuard, { action: "create", entity: "branch", children: _jsx(BranchCreate, {}) })),
                        },
                        {
                            path: ':id',
                            element: (_jsx(AuthorizationGuard, { action: "view", entity: "branch", children: _jsx(BranchDetails, {}) })),
                        },
                        {
                            path: ':id/edit',
                            element: (_jsx(AuthorizationGuard, { action: "edit", entity: "branch", children: _jsx(BranchEdit, {}) })),
                        },
                    ],
                },
                {
                    path: 'fleet',
                    children: [
                        {
                            path: 'classes',
                            children: [
                                {
                                    element: (_jsx(AuthorizationGuard, { action: "list", entity: "class", children: _jsx(Classes, {}) })),
                                    index: true,
                                },
                                {
                                    path: 'new',
                                    element: (_jsx(AuthorizationGuard, { action: "create", entity: "class", children: _jsx(ClassCreatePage, {}) })),
                                },
                                {
                                    path: ':id/edit',
                                    element: (_jsx(AuthorizationGuard, { action: "edit", entity: "class", children: _jsx(ClassEditPage, {}) })),
                                },
                                {
                                    path: ':id',
                                    element: (_jsx(AuthorizationGuard, { action: "view", entity: "class", children: _jsx(ClassDetailsPage, {}) })),
                                },
                            ],
                        },
                        {
                            path: 'rentable-types',
                            children: [
                                {
                                    element: (_jsx(AuthorizationGuard, { action: "list", entity: "rentableType", children: _jsx(RentableTypeList, {}) })),
                                    index: true,
                                },
                                {
                                    path: 'new',
                                    element: (_jsx(AuthorizationGuard, { action: "create", entity: "rentableType", children: _jsx(RentableTypeCreate, {}) })),
                                },
                                {
                                    path: ':id/edit',
                                    element: (_jsx(AuthorizationGuard, { action: "edit", entity: "rentableType", children: _jsx(RentableTypeEdit, {}) })),
                                },
                                {
                                    path: ':id',
                                    element: (_jsx(AuthorizationGuard, { action: "view", entity: "rentableType", children: _jsx(RentableTypeView, {}) })),
                                },
                            ],
                        },
                        {
                            path: 'rentable-extra-types',
                            children: [
                                {
                                    element: (_jsx(AuthorizationGuard, { action: "list", entity: "rentableType", children: _jsx(RentableExtraTypeList, {}) })),
                                    index: true,
                                },
                                {
                                    path: 'new',
                                    element: (_jsx(AuthorizationGuard, { action: "create", entity: "rentableType", children: _jsx(RentableExtraTypeCreate, {}) })),
                                },
                                {
                                    path: ':id/edit',
                                    element: (_jsx(AuthorizationGuard, { action: "edit", entity: "rentableType", children: _jsx(RentableExtraTypeEdit, {}) })),
                                },
                                {
                                    path: ':id',
                                    element: (_jsx(AuthorizationGuard, { action: "view", entity: "rentableType", children: _jsx(RentableExtraTypeView, {}) })),
                                },
                            ],
                        },
                        {
                            path: 'brands',
                            children: [
                                {
                                    element: (_jsx(AuthorizationGuard, { action: "list", entity: "brand", children: _jsx(BrandList, {}) })),
                                    index: true,
                                },
                                {
                                    path: 'new',
                                    element: (_jsx(AuthorizationGuard, { action: "create", entity: "brand", children: _jsx(BrandCreate, {}) })),
                                },
                                {
                                    path: ':id/edit',
                                    element: (_jsx(AuthorizationGuard, { action: "edit", entity: "brand", children: _jsx(BrandEdit, {}) })),
                                },
                                {
                                    path: ':id',
                                    element: (_jsx(AuthorizationGuard, { action: "view", entity: "brand", children: _jsx(BrandView, {}) })),
                                },
                            ],
                        },
                        {
                            path: 'models',
                            children: [
                                {
                                    element: (_jsx(AuthorizationGuard, { action: "list", entity: "model", children: _jsx(ModelList, {}) })),
                                    index: true,
                                },
                                {
                                    path: 'new',
                                    element: (_jsx(AuthorizationGuard, { action: "create", entity: "model", children: _jsx(ModelCreate, {}) })),
                                },
                                {
                                    path: ':id/edit',
                                    element: (_jsx(AuthorizationGuard, { action: "edit", entity: "model", children: _jsx(ModelEdit, {}) })),
                                },
                                {
                                    path: ':id',
                                    element: (_jsx(AuthorizationGuard, { action: "view", entity: "model", children: _jsx(ModelView, {}) })),
                                },
                            ],
                        },
                        { element: _jsx(Navigate, { to: "brands", replace: true }), index: true },
                        {
                            path: 'rentals',
                            element: (_jsx(AuthorizationGuard, { action: "list", entity: "rentable", children: _jsx(Rentals, {}) })),
                        },
                        {
                            path: 'rentals/new',
                            element: (_jsx(AuthorizationGuard, { action: "create", entity: "rentable", children: _jsx(RentalCreatePage, {}) })),
                        },
                        {
                            path: 'rentals/edit/:id',
                            element: (_jsx(AuthorizationGuard, { action: "edit", entity: "rentable", children: _jsx(RentalEditPage, {}) })),
                        },
                        {
                            path: 'rentals/show/:name',
                            element: (_jsx(AuthorizationGuard, { action: "view", entity: "rentable", children: _jsx(RentalDetailsPage, {}) })),
                        },
                    ],
                },
                {
                    path: 'finance',
                    children: [
                        { element: _jsx(Navigate, { to: "/finance/invoices", replace: true }), index: true },
                        {
                            path: 'invoices',
                            children: [
                                {
                                    element: (_jsx(AuthorizationGuard, { action: "list", entity: "invoices", children: _jsx(InvoiceList, {}) })),
                                    index: true,
                                },
                                {
                                    path: 'new',
                                    element: (_jsx(AuthorizationGuard, { action: "create", entity: "invoices", children: _jsx(InvoiceCreate, {}) })),
                                },
                                {
                                    path: ':id',
                                    element: (_jsx(AuthorizationGuard, { action: "view", entity: "invoices", children: _jsx(InvoiceView, {}) })),
                                },
                                {
                                    path: ':id/edit',
                                    element: (_jsx(AuthorizationGuard, { action: "edit", entity: "invoices", children: _jsx(InvoiceEdit, {}) })),
                                },
                            ],
                        },
                        {
                            path: 'expenses',
                            children: [
                                {
                                    element: (_jsx(AuthorizationGuard, { action: "list", entity: "expenses", children: _jsx(ExpenseList, {}) })),
                                    index: true,
                                },
                                {
                                    path: 'new',
                                    element: (_jsx(AuthorizationGuard, { action: "create", entity: "expenses", children: _jsx(ExpenseCreate, {}) })),
                                },
                                {
                                    path: ':id',
                                    element: (_jsxs(AuthorizationGuard, { action: "view", entity: "expenses", children: [' ', _jsx(ExpenseDetails, {})] })),
                                },
                                {
                                    path: ':id/edit',
                                    element: (_jsx(AuthorizationGuard, { action: "edit", entity: "expenses", children: _jsx(ExpenseEdit, {}) })),
                                },
                            ],
                        },
                        {
                            path: 'accounts',
                            children: [
                                { element: _jsx(Navigate, { to: "/finance/accounts/bank-accounts", replace: true }), index: true },
                                {
                                    path: 'bank-accounts',
                                    children: [
                                        {
                                            element: (_jsx(AuthorizationGuard, { action: "list", entity: "bankAccount", children: _jsx(BankAccountList, {}) })),
                                            index: true,
                                        },
                                        {
                                            path: 'new',
                                            element: (_jsx(AuthorizationGuard, { action: "create", entity: "bankAccount", children: _jsx(BankAccountCreate, {}) })),
                                        },
                                        {
                                            path: ':id',
                                            element: (_jsx(AuthorizationGuard, { action: "view", entity: "bankAccount", children: _jsx(BankAccountDetails, {}) })),
                                        },
                                        {
                                            path: ':id/edit',
                                            element: (_jsx(AuthorizationGuard, { action: "edit", entity: "bankAccount", children: _jsx(BankAccountEdit, {}) })),
                                        },
                                    ],
                                },
                                {
                                    path: 'branch-cashboxes',
                                    children: [
                                        {
                                            element: (_jsx(AuthorizationGuard, { action: "list", entity: "branchCashbox", children: _jsx(BranchCashboxList, {}) })),
                                            index: true,
                                        },
                                        {
                                            path: 'new',
                                            element: (_jsx(AuthorizationGuard, { action: "create", entity: "branchCashbox", children: _jsx(BranchCashboxCreate, {}) })),
                                        },
                                        {
                                            path: ':id',
                                            element: (_jsx(AuthorizationGuard, { action: "view", entity: "branchCashbox", children: _jsx(BranchCashboxDetails, {}) })),
                                        },
                                        {
                                            path: ':id/edit',
                                            element: (_jsx(AuthorizationGuard, { action: "edit", entity: "branchCashbox", children: _jsx(BranchCashboxEdit, {}) })),
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    path: 'pricing',
                    children: [
                        { element: _jsx(Navigate, { to: "/pricing/pricing-groups", replace: true }), index: true },
                        {
                            path: 'pricing-groups',
                            children: [
                                {
                                    element: (_jsx(AuthorizationGuard, { action: "list", entity: "pricingGroup", children: _jsx(PricingGroupList, {}) })),
                                    index: true,
                                },
                                {
                                    path: 'new',
                                    element: (_jsx(AuthorizationGuard, { action: "create", entity: "pricingGroup", children: _jsx(PricingGroupCreate, {}) })),
                                },
                                {
                                    path: ':id',
                                    element: (_jsx(AuthorizationGuard, { action: "view", entity: "pricingGroup", children: _jsx(PricingGroupDetails, {}) })),
                                },
                                {
                                    path: ':id/edit',
                                    element: (_jsx(AuthorizationGuard, { action: "edit", entity: "pricingGroup", children: _jsx(PricingGroupEdit, {}) })),
                                },
                            ],
                        },
                        {
                            path: 'seasons',
                            children: [
                                {
                                    element: (_jsx(AuthorizationGuard, { action: "list", entity: "season", children: _jsx(Seasons, {}) })),
                                    index: true,
                                },
                                {
                                    path: 'new',
                                    element: (_jsx(AuthorizationGuard, { action: "create", entity: "season", children: _jsx(SeasonCreate, {}) })),
                                },
                                {
                                    path: ':id',
                                    element: (_jsx(AuthorizationGuard, { action: "view", entity: "season", children: _jsx(SeasonsDetailPage, {}) })),
                                },
                                {
                                    path: ':name/edit',
                                    element: (_jsx(AuthorizationGuard, { action: "edit", entity: "season", children: _jsx(SeasonEditPage, {}) })),
                                },
                            ],
                        },
                    ],
                },
                {
                    path: 'reservations',
                    children: [
                        { element: _jsx(Navigate, { to: "/reservations/list", replace: true }), index: true },
                        {
                            path: 'list',
                            element: (_jsx(AuthorizationGuard, { action: "list", entity: "reservation", children: _jsx(ReservationList, {}) })),
                        },
                        {
                            path: 'rejected',
                            element: (_jsx(AuthorizationGuard, { action: "list", entity: "reservation", children: _jsx(ReservationRejectedList, {}) })),
                        },
                        {
                            path: ':id',
                            element: (_jsx(AuthorizationGuard, { action: "view", entity: "reservation", children: _jsx(ReservationDetails, {}) })),
                        },
                        {
                            path: 'calendar',
                            element: (_jsx(AuthorizationGuard, { action: "list", entity: "reservation", children: _jsx(Calender, {}) })),
                        },
                        {
                            path: 'new',
                            element: (_jsx(AuthorizationGuard, { action: "create", entity: "reservation", children: _jsx(CreateReservation, {}) })),
                        },
                        {
                            path: ':id/edit',
                            element: (_jsx(AuthorizationGuard, { action: "edit", entity: "reservation", children: _jsx(ReservationEditPage, {}) })),
                        },
                    ],
                },
                {
                    path: 'customers',
                    children: [
                        {
                            element: (_jsx(AuthorizationGuard, { action: "list", entity: "customer", children: _jsx(CustomerList, {}) })),
                            index: true,
                        },
                        {
                            path: ':id',
                            element: (_jsx(AuthorizationGuard, { action: "view", entity: "customer", children: _jsx(CustomerDetails, {}) })),
                        },
                    ],
                },
                {
                    path: '/permission-groups',
                    element: (_jsx(AuthorizationGuard, { action: "list", entity: "group", children: _jsx(Permissions, {}) })),
                },
                {
                    path: 'settings',
                    children: [
                        { element: _jsx(Navigate, { to: "/settings/agency", replace: true }), index: true },
                        {
                            path: 'agency',
                            children: [
                                {
                                    element: (_jsx(AuthorizationGuard, { action: "list", entity: "agency", children: _jsx(AgenciesList, {}) })),
                                    index: true,
                                },
                                {
                                    path: 'new',
                                    element: (_jsx(AuthorizationGuard, { action: "create", entity: "agency", children: _jsx(CreateAgencyPage, {}) })),
                                },
                                {
                                    path: ':id/edit',
                                    element: (_jsx(AuthorizationGuard, { action: "edit", entity: "agency", children: _jsx(EditAgencyPage, {}) })),
                                },
                                {
                                    path: ':id',
                                    element: (_jsx(AuthorizationGuard, { action: "view", entity: "agency", children: _jsx(ShowAgencyPage, {}) })),
                                },
                            ],
                        },
                        {
                            path: 'users',
                            children: [
                                {
                                    element: (_jsx(AuthorizationGuard, { action: "list", entity: "user", children: _jsx(UsersList, {}) })),
                                    index: true,
                                },
                                {
                                    path: ':id/edit',
                                    element: (_jsx(AuthorizationGuard, { action: "edit", entity: "user", children: _jsx(EditUsersPage, {}) })),
                                },
                                {
                                    path: 'new',
                                    element: (_jsx(AuthorizationGuard, { action: "create", entity: "user", children: _jsx(CreateUserPage, {}) })),
                                },
                                {
                                    path: ':id',
                                    element: (_jsx(AuthorizationGuard, { action: "view", entity: "user", children: _jsx(UsersDetailPage, {}) })),
                                },
                            ],
                        },
                        {
                            path: 'expense-configs',
                            children: [
                                {
                                    element: (_jsxs(AuthorizationGuard, { action: "list", entity: "expenseConfig", children: [' ', _jsx(ExpenseConfigList, {})] })),
                                    index: true,
                                },
                                {
                                    path: ':id/edit',
                                    element: (_jsx(AuthorizationGuard, { action: "edit", entity: "expenseConfig", children: _jsx(ExpenseConfigEdit, {}) })),
                                },
                                {
                                    path: 'new',
                                    element: (_jsx(AuthorizationGuard, { action: "create", entity: "expenseConfig", children: _jsx(ExpenseConfigCreate, {}) })),
                                },
                            ],
                        },
                        { path: 'bookableExtras', element: _jsx(Page404, {}) },
                    ],
                },
                {
                    path: 'affiliations',
                    children: [
                        {
                            element: (_jsx(RoleBasedGuard, { roles: ['SUPERADMIN'], children: _jsx(AffiliationList, {}) })),
                            index: true,
                        },
                        {
                            path: 'new',
                            element: (_jsx(RoleBasedGuard, { roles: ['SUPERADMIN'], children: _jsx(AffiliationCreate, {}) })),
                        },
                        {
                            path: ':id',
                            element: (_jsx(RoleBasedGuard, { roles: ['SUPERADMIN'], children: _jsx(AffiliationView, {}) })),
                        },
                        {
                            path: ':id/edit',
                            element: (_jsx(RoleBasedGuard, { roles: ['SUPERADMIN'], children: _jsx(AffiliationEdit, {}) })),
                        },
                    ],
                },
            ],
        },
        {
            element: _jsx(CompactLayout, {}),
            children: [
                { path: '404', element: _jsx(Page404, {}) },
                { path: '403', element: _jsx(Page403, {}) },
            ],
        },
        { path: '*', element: _jsx(Navigate, { to: "/404", replace: true }) },
    ]);
}
